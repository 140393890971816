<template>
    <div>
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="下级账号">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <form action="#" class="searchbox">
                            <input type="text" class="text search-input" placeholder="输入关键词搜索...">
                            <a class="search-link" href="#"><i class="el-icon-search"></i></a>
                        </form>
                    </div>
                    <el-button type="primary" size="small" @click="AddUserBtn" plain>添加员工</el-button>
                    <el-button type="primary" size="small" plain>批量升级</el-button>
                    <el-button type="primary" size="small" plain>批量续费</el-button>
                </div>
                <el-table :data="UserData" stripe style="width: 100%;" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="userName" label="头像">
                    </el-table-column>
                    <el-table-column prop="userName" label="姓名">
                    </el-table-column>
                    <el-table-column prop="phone" label="账号" width="110px">
                    </el-table-column>
                    <el-table-column prop="telephone" label="剩余天数">
                    </el-table-column>
                    <el-table-column prop="mail" label="用户版本">
                    </el-table-column>
                    <el-table-column prop="department.fullName" label="所属部门">
                    </el-table-column>
                    <el-table-column prop="userPost.userPostName" label="所在区域">
                    </el-table-column>
                    <el-table-column prop="userTitle.userTitleName" label="职务">
                    </el-table-column>
                    <el-table-column prop="userQualification.name" label="是否分销">
                    </el-table-column>
                    <!-- <el-table-column prop="role.roleName"
                                     label="添加时间">
                    </el-table-column> -->
                    <el-table-column fixed="right" label="操作" width="400">
                        <template slot-scope="scope">
                            <span class="info optionBtn" @click="EditUserBtn(scope.row)">编辑</span>
                            <span class="success optionBtn" @click="Unbind(scope.row)">解绑</span>
                            <span class="warning optionBtn" @click="DisableUser(scope.row)">续费</span>
                            <span class="danger optionBtn" @click="RemoveUser(scope.row, item)">升级版本</span>
                            <span class="danger optionBtn" @click="ResetPwd(scope.row, item)">密码重置</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!--    Dialog 新增    -->
        <el-drawer :direction="direction" :size="size" custom-class="demo-drawer" :visible.sync="AddUserDialog">
            <h1 slot="title" v-html="DialogName"></h1>
            <div class="addhospital">
                <el-form :model="AddUserData" :rules="rules" ref="AddUserData" label-width="100px">
                    <el-row :gutter="30">
                        <el-col :span="8">
                            <div class="form-box" style="padding-bottom: 50px;">
                                <div class="headImg">
                                    <div class="headimgBox">
                                        <img src="../../../assets/img/login_img.png" alt="">
                                    </div>
                                    <el-button class="upload-btn" type="primary" icon="el-icon-edit" circle></el-button>
                                </div>
                                <el-form-item label="所属机构" prop="userName">
                                    <el-input v-model="AddUserData.userName" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="职务" prop="userTitleId">
                                    <el-select v-model="AddUserData.userTitleId" style="width: 100%;" clearable
                                        placeholder="请选择用户职称">
                                        <el-option v-for="item in UserTitleData" :key="item.id" :label="item.userTitleName"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="所属科室" prop="departmentId">
                                    <el-cascader :options="DepartmentData"
                                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                                        v-model="AddUserData.departmentId" style="width: 100%;" clearable></el-cascader>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="16">
                            <el-scrollbar class="left-nav-wrap" style="height: 820px;">
                                <div class="form-box">
                                    <span>员工资料</span>
                                    <el-divider></el-divider>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="姓名" prop="userName">
                                                <el-input v-model="AddUserData.userName" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="用户昵称" prop="userName">
                                                <el-input v-model="AddUserData.userName" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="联系电话" prop="phone">
                                                <el-input v-model="AddUserData.phone" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="性别" prop="sex">
                                                <el-select v-model="AddUserData.sex" style="width: 100%;" placeholder="性别">
                                                    <el-option label="男" :value="1"></el-option>
                                                    <el-option label="女" :value="0"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="生日">
                                                <el-date-picker v-model="AddUserData.birthday" style="width: 100%;" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                                                </el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="学历">
                                                <el-select v-model="AddUserData.userQualificationId" style="width: 100%;"
                                                    clearable placeholder="请选择用户学历">
                                                    <el-option v-for="item in UserQualificationData" :key="item.id"
                                                        :label="item.name" :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>

                                        <el-col :span="24">
                                            <el-form-item label="联系地址" prop="regionId">
                                                <el-cascader
                                                    :placeholder="AddUserData.region ? AddUserData.region.fullName : '请选择所在区域'"
                                                    v-model="AddUserData.regionId" :options="RegionData" size="medium"
                                                    :props="{ emitPath: false, multiple: false, checkStrictly: true, lazy: true, lazyLoad: LasyLoadRegion, value: 'id', label: 'regionName', children: 'childrens' }"
                                                    style="margin-right: 10px;width: 100%;margin-bottom: 22px;">
                                                </el-cascader>
                                                <el-input v-model="AddUserData.address" placeholder="请输入详细地址"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <!-- <el-col :span="12">
                                            <el-form-item label="电子邮箱" prop="mail">
                                                <el-input v-model="AddUserData.mail" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </el-col> -->
                                        <el-col :span="12">
                                            <el-form-item label="是否分销员" prop="isDistributor">
                                                <el-radio-group size="medium" v-model="AddUserData.isDistributor">
                                                    <el-radio-button :label="true">是</el-radio-button>
                                                    <el-radio-button :label="false">否</el-radio-button>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                            <el-form-item label="个人介绍">
                                                <quill-editor v-model="AddUserData.abstract" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <span>账户信息</span>
                                    <el-divider></el-divider>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-col :span="12">
                                                <el-form-item label="用户账号" prop="phone">
                                                    <el-input v-model="AddUserData.phone" autocomplete="off"
                                                        disabled></el-input>
                                                    <el-button type="text" style="position: absolute;top: -3px;"><i
                                                            class="el-icon-question"
                                                            style="font-size: 18px;"></i>登录账号为手机号</el-button>
                                                </el-form-item>
                                            </el-col>
                                        </el-col>

                                        <el-col :span="12">
                                            <el-form-item label="登录密码" v-show="!AddUserData.id">
                                                <el-input v-model="AddUserData.password" show-password autocomplete="off"
                                                    :disabled="disabled"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="确认密码" v-show="!AddUserData.id">
                                                <el-input v-model="AddUserData.confirmPwd" show-password autocomplete="off"
                                                    :disabled="disabled"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-checkbox v-model="AddUserData.checked" v-show="!AddUserData.id"
                                            style="margin-left: 32px" @change="IsChecked">使用默认密码 <span
                                                style="color: #0C9A9A">(默认为：123456)</span></el-checkbox>
                                    </el-row>
                                    <div slot="footer" class="dialog-footer">
                                        <el-divider></el-divider>
                                        <el-button @click="cancelDialog('AddUserData')">取 消</el-button>
                                        <el-button type="primary" @click="SaveUser('AddUserData')">确 定</el-button>
                                    </div>
                                </div>
                            </el-scrollbar>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { Invoice } from "../../../components/HospitalDomain/Invoice";
import { UserTitle } from '../../../components/HospitalDomain/UserTitle';
import { Department } from '../../../components/HospitalDomain/Department';
import { Region } from '../../../components/HospitalDomain/Region';
import { UserQualification } from '../../../components/HospitalDomain/UserQualification'
export default {
    name: "OrganizeUser",
    components: {
        bread
    },
    data() {
        var invoice = new Invoice(this.TokenClient, this.Services.Authorization)
        var userTitle = new UserTitle(this.TokenClient, this.Services.Authorization);
        var department = new Department(this.TokenClient, this.Services.Authorization);
        var region = new Region(this.TokenClient, this.Services.Authorization);
        var userQualification = new UserQualification(this.TokenClient, this.Services.Authorization);
        return {
            Invoice: invoice,
            Region: region,
            UserTitle: userTitle,
            Department: department,
            UserQualification: userQualification,
            RegionData: [],
            UserTitleData: [],
            DepartmentData: [],
            UserQualificationData: [],
            disabled: false,
            AddUserData: {
                "id": 0,
                "userName": "",
                "password": "",
                "confirmPwd": "",
                "phone": "",
                "doctorNo": "",
                "mail": "",
                "birthday": "",
                "sex": 1,
                "departmentId": null,
                "userPostId": null,
                "userTitleId": null,
                "organizationId": null,
                "roleId": 0,
                "userStatu": 1,
                "organization": {},
                "telephone": '',
                "address": "",
                "abstract": "",
                "regionId": null,
                "userQualificationId": null,
                "greenChannel": false,
                "params": {},
                "checked": false,
                "isDistributor": false
            },
            rules: {
                userName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
                ],
                mail: [
                    // { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
                ],
                birthday: [
                    { required: true, message: '请选择时间', trigger: 'blur' },
                    { type: 'date', message: '请选择时间', trigger: 'blur' }
                ],
                departmentId: [
                    { required: true, message: '请选择所属部门', trigger: 'blur' }
                ],
                regionId: [
                    { required: true, message: '请选择地区', trigger: 'blur' }
                ],
                sex: [
                    { required: true, message: '请选择性别', trigger: 'blur' }
                ],
                userPostId: [
                    { required: true, message: '请选择用户职位', trigger: 'blur' }
                ],
                userTitleId: [
                    { required: true, message: '请选择用户职称', trigger: 'blur' }
                ],
                roleId: [
                    { required: true, message: '请选择用户角色', trigger: 'blur' }
                ]
            },
            direction: 'ttb',
            size: '100%',
            AddUserDialog: false,
            DialogName: ''
        }
    },
    mounted() {
        this.getorganization()
        this.getbasedata()
    },
    methods: {
        IsChecked(data) {
            var _this = this;
            _this.disabled = data;
            if (data) {
                _this.AddUserData.password = '123456';
                _this.AddUserData.confirmPwd = '123456';
            } else {
                _this.AddUserData.password = '';
                _this.AddUserData.confirmPwd = '';
            }
        },
        getbasedata() {
            var _this = this;
            // 获取职位
            _this.UserTitle.HospitalTitles(
                function (data) {
                    _this.UserTitleData = data.data;
                },
                function (err) { console.log(err); });
            // 获取部门
            _this.Department.HospitalDepartment(
                function (data) {
                    _this.DepartmentData = data.data;
                },
                function (err) { console.log(err); });
            // 获取区域
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data; },
                function (err) { console.log(err); });
            // 获取学历
            _this.UserQualification.Qualifications(
                function (data) {
                    _this.UserQualificationData = data.data;
                },
                function (err) { console.log(err); });
        },
        AddUserBtn() {
            var _this = this;
            _this.DialogName = '新增员工账号';
            _this.AddUserData.id = 0;
            _this.AddUserData.userName = '';
            _this.AddUserData.password = '';
            _this.AddUserData.confirmPwd = '';
            _this.AddUserData.checked = false
            _this.AddUserData.phone = '';
            _this.AddUserData.doctorNo = '';
            _this.AddUserData.mail = '';
            _this.AddUserData.birthday = new Date();
            _this.AddUserData.sex = 1;
            _this.AddUserData.departmentId = null;
            _this.AddUserData.userPostId = null;
            _this.AddUserData.userTitleId = null;
            _this.AddUserData.organizationId = null;
            _this.AddUserData.telephone = '';
            _this.AddUserData.address = '';
            _this.AddUserData.abstract = '';
            _this.AddUserData.regionId = '';
            _this.AddUserData.roleId = 0;
            _this.AddUserData.userQualificationId = null;
            _this.AddUserData.greenChannel = false;
            _this.AddUserDialog = true;
        },
        LasyLoadRegion(node, resolve) {
            var _this = this;
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 5;
                    }
                    resolve(data.data);
                },
                function (err) {
                    resolve([]);
                });
        },
        getorganization() {
            let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
            var _this = this
            _this.Invoice.organizationInfo(organizationId, function (data) {
                _this.organizationData = data.data;
            }, function (err) { console.log(err); });
        },
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}

.dialog-form {
    width: 430px;
}

/**/
.addhospital {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.addhospital .el-form {
    flex: 1;
}

.addhospital .form-box {
    padding: 30px 50px 30px 30px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
}

.headImg {
    position: relative;
    margin-bottom: 25px;
}

.headimgBox {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    border: 5px solid rgba(12, 154, 154, 0.28);
}

.headimgBox img {
    width: 100%;
}

.headImg .upload-btn {
    position: absolute;
    left: 57%;
    bottom: 0px;
}
</style>
<style>
.staff .el-drawer {
    background-color: #eff7f8;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
    overflow: scroll !important;
}
</style>
